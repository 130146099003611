<template>
  <div>
    <validation-observer v-slot="{ invalid }" ref="settings">
      <b-card-code title="Settaggi">
        <b-row>
          <b-col md="6" lg="6">
            <b-form-group
              :label="`${$t('settings.browser_title')} *`"
              label-for="browser_title"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="nome"
              >
                <b-form-input
                  id="browser_title"
                  :placeholder="$t('settings.browser_title')"
                  v-model="title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" lg="3">
            <b-form-group :label="$t('settings.logo')">
              <b-form-file
                accept=".jpg, .png, .svg"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="logo"
                @input="logoPreview(logo)"
              />
              <b-img :src="logo_link" class="preview-img-setting" />
            </b-form-group>
          </b-col>
          <b-col md="3" lg="3">
            <b-form-group :label="$t('settings.favicon')">
              <b-form-file
                accept=".jpg, .png, .svg, .ico"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="favicon"
                @input="faviconPreview(favicon)"
              />
              <b-img :src="favicon_link" class="preview-img-setting" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" lg="4">
            <b-form-group :label="$t('settings.totem_logo')">
              <b-form-file
                accept=".jpg, .png, .svg"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="totem_logo"
                @input="openEditorTotem(totem_logo, 'totem_logo')"
              />
              <b-img :src="totem_logo_link" class="preview-img-setting" />
            </b-form-group>
          </b-col>
          <b-col md="4" lg="4">
            <b-form-group :label="$t('settings.conference_logo')">
              <b-form-file
                accept=".jpg, .png, .svg"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="conference_logo"
                @input="
                  openEditorConference(conference_logo, 'conference_logo')
                "
              />
              <b-img :src="conference_logo_link" class="preview-img-setting" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" lg="4">
            <b-form-group :label="$t('settings.logo_media_press')">
              <b-form-file
                accept=".png, .jpg"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="logo_media_press"
                @input="
                  openEditorMediaPress(logo_media_press, 'logo_media_press')
                "
              />
              <b-img :src="logo_media_press_link" class="preview-img-setting" />
            </b-form-group>
          </b-col>
          <b-col md="4" lg="4">
            <b-form-group :label="$t('settings.banner_conference')">
              <b-form-file
                accept=".jpg, .png, .svg"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="banner_conference"
                @input="
                  openEditorBannerConference(
                    banner_conference,
                    'banner_conference'
                  )
                "
              />
              <b-img
                :src="banner_conference_link"
                class="preview-img-setting"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="6">
            <b-form-group
              :label="$t('short_description')"
              label-for="stand_description"
            >
              <b-form-textarea
                id="stand_description"
                v-model="description"
                :placeholder="$t('add_short_description')"
                rows="4"
                class="char-textarea"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" lg="6">
            <b-form-group :label="$t('settings.preview_img')">
              <b-form-file
                accept=".jpg, .png, .svg, .ico"
                ref="file-input"
                :placeholder="$t('any_file_select')"
                v-model="image"
                @input="img_previewPreview(image)"
              />
              <b-img :src="image_link" class="preview-img-setting" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group :label="`${$t('settings.start_date')} *`">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="nome"
              >
                <flat-pickr
                  placeholder="YYYY-MM-DD"
                  class="form-control"
                  v-model="start_date"
                  :config="{
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group :label="`${$t('settings.start_date')} *`">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="nome"
              >
                <flat-pickr
                  placeholder="YYYY-MM-DD"
                  class="form-control"
                  v-model="end_date"
                  :config="{
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group :label="$t('settings.login')">
              <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                switch
                v-model="login"
              />
            </b-form-group>
            <b-form-group :label="$t('settings.login_mandatory')" v-if="login">
              <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                switch
                v-model="login_required"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group :label="$t('settings.firebase')">
              <div
                style="display: flex;flex-direction: row;align-items: center;"
              >
                <b-form-checkbox
                  checked="true"
                  class="custom-control-primary"
                  switch
                  v-model="firebase"
                  @change="checkedFirebase"
                />
                <b-button
                  v-if="firebase"
                  v-b-modal="`modal-firebase`"
                  class="btn-icon rounded-circle"
                  variant="flat-primary"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
            </b-form-group>
            <b-form-group :label="$t('settings.active_menu')">
              <b-button
                v-b-modal="`modal-active-menu`"
                class="btn-icon rounded-circle"
                variant="flat-primary"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-form-group>
          </b-col>
          <b-col md="5" lg="5">
            <b-form-group
              :label="`${$t('settings.landing_url')}`"
              label-for="landing_url"
            >
              <b-form-input
                id="landing_url"
                :placeholder="$t('settings.landing_url')"
                v-model="landing_url"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row style="justify-content: flex-end;">
          <b-col md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              class="mt-2"
              @click.prevent="saveSettings"
              :disabled="invalid"
            >
              {{ $t("save_edit") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-code>
    </validation-observer>
    <firebase-modal :firebase_config="firebase_config"></firebase-modal>
    <active-menu-modal :menu="menu"></active-menu-modal>

    <modal-editor
      :image="image_logo"
      :type="type"
      :width="width"
      :height="height"
      @onSave="showPreview"
      @close="abortImageEdit"
    ></modal-editor>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  VBModal,
  BImg,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import ModalEditor from "../editor.vue";

import FirebaseModal from "./firebase-modal.vue";
import ActiveMenuModal from "./active-menu-modal.vue";

import { getSettings, saveSettings } from "@api/settings";

export default {
  components: {
    BCardCode,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCard,
    ModalEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BImg,
    VBModal,
    BFormCheckbox,
    BButton,
    FirebaseModal,
    ActiveMenuModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    this.getSettings();
  },
  data() {
    return {
      title: "",
      description: "",
      logo: null,
      logo_link: "",
      favicon: null,
      favicon_link: "",
      image: null,
      image_link: "",
      type: null,
      image_logo: null,
      totem_logo: null,
      totem_logo_link: "",
      conference_logo: null,
      conference_logo_link: "",
      logo_media_press: null,
      logo_media_press_link: "",
      banner_conference: null,
      banner_conference_link: "",
      width: 720,
      height: 1280,
      start_date: null,
      end_date: null,
      login: false,
      login_required: false,
      firebase: false,
      landing_url: "",
      firebase_config: {
        apiKey: "",
        authDomain: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: "",
        clientId: "",
        propertyId: "",
      },
      menu: {
        stand: true,
        totem: true,
        meeting_point: true,
        conference: true,
        users: true,
        personalization: true,
        notication: true,
        editor: true,
        statistics: true,
      },
    };
  },
  mounted() {
    document.getElementById(
      "breadcrumbsPageTitle"
    ).innerHTML = this.$route.meta.pageTitle;
  },
  methods: {
    getSettings() {
      const $this = this;
      getSettings()
        .then((data) => {
          $this.updateData(data);
        })
        .catch(() => {
          $this.$router.push({ path: `/error-404/` });
        });
    },
    updateData(data) {
      this.title = data.title;
      this.description = data.description;
      this.logo_link = data.logo;
      this.favicon_link = data.favicon;
      this.image_link = data.image;
      this.totem_logo_link = data.logo_totem;
      this.conference_logo_link = data.logo_conference;
      this.logo_media_press_link = data.media_press;
      this.banner_conference_link = data.banner_conference;
      this.start_date = data.start_date;
      this.end_date = data.end_date;
      this.login = data.login === "1";
      this.login_required = data.login_required === "1";
      if (data.menu != "") {
        this.menu = JSON.parse(data.menu);
      }
      if (data.firebase != "") {
        this.firebase = true;
        this.firebase_config = JSON.parse(data.firebase);
      }
      this.landing_url = data.landing_url;
    },
    saveSettings() {
      const $this = this;
      this.$refs.settings.validate().then((success) => {
        if (success) {
          $this.save();
        }
      });
      return false;
    },
    save() {
      let $this = this;
      let formData = new FormData();
      formData.append("title", $this.title);
      formData.append("description", $this.description);
      if ($this.logo) {
        formData.append("logo", $this.logo);
      }
      if ($this.favicon) {
        formData.append("favicon", $this.favicon);
      }
      if ($this.image) {
        formData.append("image", $this.image);
      }
      if ($this.totem_logo) {
        formData.append("logo_totem", $this.totem_logo);
      }
      if ($this.conference_logo) {
        formData.append("logo_conference", $this.conference_logo);
      }
      if ($this.logo_media_press) {
        formData.append("logo_media_press", $this.logo_media_press);
      }
      if ($this.banner_conference) {
        formData.append("banner_conference", $this.banner_conference);
      }
      formData.append("start_date", $this.start_date);
      formData.append("end_date", $this.end_date);
      if ($this.firebase) {
        formData.append("firebase", JSON.stringify($this.firebase_config));
      } else {
        formData.append("firebase", "");
      }
      formData.append("menu", JSON.stringify($this.menu));
      formData.append("login", $this.login ? 1 : 0);
      formData.append("login_required", $this.login_required ? 1 : 0);
      formData.append("landing_url", $this.landing_url);
      saveSettings(formData)
        .then((data) => {
          $this.updateData(data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("save_complete"),
              icon: "BellIcon",
            },
          });
        })
        .catch(() => {
          $this.$router.push({ path: `/error-404/` });
        });
    },
    logoPreview(logo) {
      this.logo_link = URL.createObjectURL(logo);
    },
    faviconPreview(favicon) {
      this.favicon_link = URL.createObjectURL(favicon);
    },
    img_previewPreview(image) {
      this.image_link = URL.createObjectURL(image);
    },
    checkedFirebase() {
      if (this.firebase) {
        this.$bvModal.show("modal-firebase");
      }
    },
    openEditorTotem(file, type) {
      if (file != null) {
        this.width = 1000;
        this.height = 300;
        this.image_logo = file;
        this.type = type;
        this.$bvModal.show("modal-editor");
      }
    },
    openEditorConference(file, type) {
      if (file != null) {
        this.width = 900;
        this.height = 300;
        this.image_logo = file;
        this.type = type;
        this.$bvModal.show("modal-editor");
      }
    },
    openEditorMediaPress(file, type) {
      if (file != null) {
        this.width = 900;
        this.height = 300;
        this.image_logo = file;
        this.type = type;
        this.$bvModal.show("modal-editor");
      }
    },
    openEditorBannerConference(file, type) {
      if (file != null) {
        this.width = 1500;
        this.height = 840;
        this.image_logo = file;
        this.type = type;
        this.$bvModal.show("modal-editor");
      }
    },
    showPreview(type, imageUrl, image) {
      if (type == "totem_logo") {
        this.totem_logo = image;
        this.totem_logo_link = imageUrl;
      } else if (type == "conference_logo") {
        this.conference_logo = image;
        this.conference_logo_link = imageUrl;
      } else if (type == "logo_media_press") {
        this.logo_media_press = image;
        this.logo_media_press_link = imageUrl;
      } else if (type == "banner_conference") {
        this.banner_conference = image;
        this.banner_conference_link = imageUrl;
      }
    },
    abortImageEdit() {},
  },
  watch: {
    login: function() {
      if (!this.login) {
        this.login_required = false;
      }
    },
  },
};
</script>

<style>
.preview-img-setting img {
  width: 200px;
  position: relative;
  left: 50%;
  top: 50%;
  padding-top: 10px;
  transform: translate(-50%, 0%);
}

.preview-img-setting {
  max-width: 250px;
  margin-top: 10px;
}
</style>
